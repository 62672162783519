<template>
    <div class="flex justify-between">
        <StatusButton v-for="(count, status) in currentStatuses" :key="status" :active="selectedClientType === status" @click="statusClicked(status)">
            <template #label>{{ capitalizeFirstLetter(status) }}</template>
            <template #count>{{ count }}</template>
        </StatusButton>
    </div>
</template>
<script>
import queryString from "query-string";
import Api from "../kycByClient.api.js";
export default {
    data() {
        return {
            currentStatuses: [],
            selectedClientType: "all",
            kycByClientsTypes: {
                all: ["Alla", "Ej utskickade", "Utkast", "Pågående", "Inskickad", "Komplettering", "Kompletterade", "Extra Check", "Godkänd", "Nekad", "Blockera"],
            },
        };
    },

    components: {
        StatusButton: () => import("@/pages/invoices/components/StatusButton.vue"),
    },

    created() {
        this.getAll();
    },

    methods: {
        async getAll() {
            const { search, clientId } = queryString.parse(window.location.search, { sort: false });
            this.currentStatuses = await Api.getKycStatusCount(search, clientId);
            const query = queryString.parse(window.location.search, { sort: false });
            this.selectedClientType = query.status ? query.status : "all";
        },
        statusClicked(status) {
            this.selectedClientType = status;
            const query = queryString.parse(window.location.search, { sort: false });
            query.status = status;
            this.$router.replace({ query }).catch(() => {});
            this.$emit("searchParametersChanged");
        },
        capitalizeFirstLetter(string) {
            const translation = {
                notSent: "Ej utskickade",
                sentToClient: "Utkast",
                inProgress: "Pågående",
                submitted: "Inskickad",
                noActionRequired: "Ingen åtgärd krävs",
                toSupplement: "Komplettering",
                supplemented: "Kompletterad",
                needExtraCheck: "Extra Check",
                denied: "Nekad",
                blocked: "Blockerad",
                all: "Alla",
            };

            return translation[string];
        },
    },

    computed: {
        // isGlobalClientSelected() {
        //     return this.$store.getters["topBar/isClientSelected"];
        // },
    },
};
</script>
